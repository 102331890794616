/* Login Page CSS */

.login-container {
  font-family: 'Inter', sans-serif;
  width: 300px;
  padding: 40px;
  position: absolute;
  top: 50%;
  left: calc(50% + 60px); /* Shift slightly right for larger screens with the navbar */
  transform: translate(-50%, -50%);
  background: #f9f9f9;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
}

.login-container h2 {
  margin-bottom: 20px;
  color: #333;
}

.login-container input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.login-container button {
  width: 100%;
  padding: 10px;
  background-color: #0d6efd;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
}

.login-container button:hover {
  background-color: #0d6efd;
}

.login-container .error-message {
  color: #D8000C;
  background-color: #FFD2D2;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
}

.register-link {
  margin-top: 20px;
}

.register-link a {
  color: #0d6efd;
  text-decoration: none;
}

.register-link a:hover {
  text-decoration: underline;
}

.forgot-password-link {
  margin-top: 20px;
}

.forgot-password-link a {
  color: #0d6efd;
  text-decoration: none;
}

.forgot-password-link a:hover {
  text-decoration: underline;
}
/* ----- MEDIA QUERIES FOR RESPONSIVENESS ----- */

/* For devices with max-width of 1024px (laptops) */
@media (max-width: 1024px) {
  .login-container {
    left: calc(50% + 40px); /* Adjust for smaller screens */
  }
}

/* For devices with max-width of 768px (tablets and mobile) */
@media (max-width: 768px) {
  .login-container {
    width: 70%; /* Allow the container to take up more width on smaller screens */
    padding: 30px; /* Reduce padding for smaller screens */
    top: 40%; /* Adjust positioning for smaller screens */
    left: 50%; /* Center the container on smaller screens */
    transform: translate(-50%, -40%);
  }

  .login-container h2 {
    font-size: 1.8em; /* Adjust heading size */
  }

  .login-container button {
    font-size: 14px;
    padding: 10px;
  }
}

/* For even smaller screens (mobile phones) with max-width of 480px) */
@media (max-width: 480px) {
  .login-container {
    width: 70%; /* Full width for mobile devices without overflow */
    padding: 20px;
    top: 30%; /* Adjust the position for very small screens */
    left: 60%; /* Center on smaller screens */
    transform: translate(-50%, -30%);
  }

  .login-container h2 {
    font-size: 1.6em; /* Smaller heading size */
  }

  .login-container input {
    padding: 8px; /* Slightly smaller input padding for small screens */
  }

  .login-container button {
    font-size: 13px; /* Smaller button font size */
    padding: 8px;
  }
}
