/* Container for the whole page */
.subscription-container {
    padding: 20px;  
    margin-top: 110px;
    margin-left: 110px;
    /* font-family: 'Handwriting', sans-serif; */
    font-family: 'Inter', sans-serif;
  }
  
  .subscription-container h2 {
    font-size: 28px;
    margin-bottom: 25px;
  }
  
  .subscription-container p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  /* Plans container */
  .plans-container {
    display: flex;
    justify-content: space-around;
    gap: 20px;
  }
  
  /* Plan card styling */
  .plan-card {
    background-color: #f0f8ff;
    border: 2px solid #87cefa;
    border-radius: 10px;
    padding: 20px;
    flex: 1;
    max-width: 300px;
  }
  
  .plan-card h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .plan-card ul {
    list-style-type: none;
    padding: 0;
    font-size: 16px;
  }
  
  .plan-card ul li {
    margin: 8px 0;
  }
  
  /* Plan buttons */
  .plan-button {
    background-color: #ff9933;
    border: none;
    color: white;
    font-size: 18px;
    padding: 10px;
    margin: 15px 0;
    cursor: pointer;
    width: 100%;
    border-radius: 8px;
  }
  
  .plan-button:hover {
    background-color: #ff8800;
  }

  .plan-button-disabled {
    background-color: gray;
    border: none;
    color: white;
    font-size: 18px;
    padding: 10px;
    margin: 15px 0;
    width: 100%;
    cursor: not-allowed;
    border-radius: 5px;
  }
  
  /* Prices */
  .price {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: center;
  }
  
  .old-price {
    text-decoration: line-through;
    font-size: 18px;
    color: #999;
    margin-right: 10px;
  }
  
  /* ----- MEDIA QUERIES FOR RESPONSIVENESS ----- */

/* For devices with max-width of 768px (tablets and mobile) */
@media (max-width: 768px) {
  .subscription-container {
    margin-top: 60px;
    margin-left: 80px; /* Leave space for the left navbar */
    margin-right: 20px; /* Add space on the right */
    padding: 15px;
    width: calc(100% - 100px); /* Adjust width based on left navbar */
  }

  .plans-container {
    flex-direction: column; /* Stack the plans vertically on smaller screens */
    gap: 15px;
    align-items: center;
  }

  .plan-card {
    width: 100%; /* Full width for plan cards on smaller screens */
    max-width: none; /* Remove the max-width constraint */
  }

  .plan-card h3 {
    font-size: 22px;
  }

  .plan-card ul {
    font-size: 14px;
  }

  .plan-button {
    font-size: 16px;
  }

  .price {
    font-size: 22px;
  }

  .old-price {
    font-size: 16px;
  }
}

/* For even smaller screens (mobile phones) with max-width of 480px */
@media (max-width: 480px) {
  .subscription-container {
    margin-top: 40px;
    margin-left: 70px; /* Slightly less margin for very small screens */
    margin-right: 10px;
    padding: 10px;
    width: calc(100% - 70px); /* Adjust the width for smaller screens */
  }

  .plans-container {
    flex-direction: column; /* Stack vertically */
    align-items: stretch; /* Ensure full-width cards */
  }

  .plan-card {
    width: 100%; /* Full width for small screens */
    padding: 15px;
  }

  .plan-card h3 {
    font-size: 20px;
  }

  .plan-card ul {
    font-size: 12px;
  }

  .plan-button {
    font-size: 14px;
  }

  .price {
    font-size: 20px;
  }

  .old-price {
    font-size: 14px;
  }
}