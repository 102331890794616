.register-container {
    font-family: 'Inter', sans-serif;
    width: 300px;
    padding: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #f9f9f9;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
  }
  
  .register-container h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .register-container input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .register-container button {
    width: 100%;
    padding: 10px;
    background-color: #0d6efd;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
  }
  
  .register-container button:hover {
    background-color: #0d6efd;
  }
  
  .register-container .error-message,
  .register-container .success-message {
    color: #D8000C;
    background-color: #FFD2D2;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
  }
  
  .register-container .success-message {
    color: green;
    background-color: #DFF2BF;
  }
  
  .login-link {
    margin-top: 20px;
  }
  
  .login-link a {
    color: #0d6efd;
    text-decoration: none;
  }
  
.login-link a:hover {
    text-decoration: underline;
}

/* ----- MEDIA QUERIES FOR RESPONSIVENESS ----- */

/* For devices with max-width of 768px (tablets and mobile) */
@media (max-width: 768px) {
  .register-container {
    width: 90%; /* Allow the container to take up more width on smaller screens */
    padding: 30px; /* Reduce padding for smaller screens */
    top: 40%; /* Adjust positioning for smaller screens */
    transform: translate(-50%, -40%);
  }

  .register-container h2 {
    font-size: 1.8em; /* Adjust heading size */
  }

  .register-container button {
    font-size: 14px;
    padding: 10px;
  }
}

/* For even smaller screens (mobile phones) with max-width of 480px) */
@media (max-width: 480px) {
  .register-container {
    width: 100% - 100px; /* Full width for mobile */
    padding: 20px;
    margin-left: 70px;
    top: 30%; /* Adjust the position for very small screens */
    transform: translate(-50%, -30%);
  }

  .register-container h2 {
    font-size: 1.6em; /* Smaller heading size */
  }

  .register-container input {
    padding: 8px; /* Slightly smaller input padding for small screens */
  }

  .register-container button {
    font-size: 13px; /* Smaller button font size */
    padding: 8px;
  }
}