/* Horizontal Top Navbar styling */
.top-navbar {
    font-family: 'Inter', sans-serif;
    background-color: #f8f9fa;
    padding: 10px 20px;
    width: 98%;
    height: 60px;
    display: flex;
    justify-content: space-between; /* Title on left, account on right */
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    border-bottom: 1px solid #e3e6ea;
    padding-right: 20px;
  }
  
  /* Logo container styling */
  .logo-container {
    display: flex;
    align-items: center; /* Vertically align the icon and text */
  }
  
  /* App Icon styling */
  .app-icon {
    width: 50px; /* Adjust this size as needed */
    height: 50px; /* Adjust this size as needed */
    margin-right: 10px; /* Space between the icon and the title */
    border-radius: 50%;
  }
  
  .logo {
    /* font-family: 'Handwriting', sans-serif; */
    font-family: 'Inter', sans-serif;
    font-size: 22px;
  }
  
  .dropdown {
    position: relative;
  }
  
  .dropdown-button {
    background-color: #0d6efd;
    color: white;
    padding: 10px;
    margin-right: 20px;
    font-size: 18px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    min-width: 160px;
    z-index: 1;
  }
  
  .dropdown-content a,
  .dropdown-content button {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    background: none;
    border: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
  }
  
  .dropdown-content a:hover,
  .dropdown-content button:hover {
    background-color: #f1f1f1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Vertical Left Navbar styling */
  .left-navbar {
    font-family: 'Inter', sans-serif;
    background-color: #f8f9fa;
    padding: 0; /* Remove padding to ensure proper hover coverage */
    width: 125px; /* Adjust width to make space for icons and text */
    height: 100vh; /* Full height of the viewport */
    position: fixed;
    top: 60px; /* Adjust for the height of the top-navbar */
    left: 0;
    display: flex;
    flex-direction: column;
    border-right: 2px solid #e3e6ea; /* Add a border on the right */
    z-index: 999; /* Make sure it stays on top of content */
  }
  
  .navbar-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 0; /* Remove gap to avoid spaces in between links */
  }
  
  .navbar-links li {
    width: 100%; /* Ensure list items take up the full width of the navbar */
    font-size: 18px;
  }
  
  .navbar-links li a {
    text-decoration: none;
    color: #333;
    padding: 15px 0; /* Adjust padding for proper spacing */
    display: flex;
    flex-direction: column; /* Stack icon and text vertically */
    align-items: center; /* Center icon and text horizontally */
    justify-content: center; /* Center vertically */
    width: 100%; /* Make sure the link spans the full width */
    text-align: center;
    height: 100px; /* Adjust height as necessary */
    border-radius: 0; /* Remove border-radius for a flat hover effect */
  }
  
  .navbar-links li.active a {
    background-color: #d6d8db; /* Light grey background for active state */
    color: #0d6efd; /* Blue text for active state */
  }
  
  .navbar-links a:hover {
    background-color: #e3e6ea;
  }
  
  .navbar-links li.active a:hover {
    background-color: #d6d8db;
  }
  
  .navbar-links li a i {
    font-size: 30px; /* Size of the icon */
    color: #0d6efd;
    margin-bottom: 5px; /* Space between icon and text */
  }
  
  /* Adjust content to avoid overlapping with the navbar */
  .main-content {
    margin-left: 220px; /* Leave space for the vertical navbar */
    padding: 20px;
    margin-top: 80px; /* Leave space for the top navbar */
  }

  .bottom-links {
    margin-top: auto; 
    font-size: 20px;
  }
  
  .bottom-links li {
    font-size: 0.9em;
  }
  
  .bottom-links li a {
    color: #333;
    text-decoration: none;
  }
  
  .bottom-links li a:hover {
    background-color: #e3e6ea;
  }

  /* ----- MEDIA QUERIES FOR RESPONSIVENESS ----- */

/* For devices with max-width of 768px (mobile and tablets) */
@media (max-width: 768px) {
  /* Adjust the top navbar height */
  .top-navbar {
    height: 50px;
  }

  /* Adjust logo and dropdown button size */
  .app-icon {
    width: 40px;
    height: 40px;
  }

  .logo {
    font-size: 18px;
  }

  .dropdown-button {
    font-size: 16px;
    padding: 8px;
  }

  /* Adjust left navbar width for smaller screens */
  .left-navbar {
    width: 80px;
  }

  /* Hide the text next to icons on smaller screens */
  .navbar-links li a span {
    display: none;
  }

  /* Adjust the main content margin for smaller screens */
  .main-content {
    margin-left: 80px;
    margin-top: 50px;
  }
}