
  /* Landing.css */

body {
    margin-bottom: 20px;
    padding: 0;
    font-family: 'Inter', sans-serif;
    background: linear-gradient(135deg, #2ecfb4, #71a3f7);
    /* color: #fff; */
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .landing-container {
    margin-top: 140px;
    max-width: 600px;
    padding: 40px;
    color: black;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .logo-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }
  
  .logo {
    width: 60px; 
    height: auto;
    margin-right: 15px;
  }
  
  .site-name {
    font-size: 2.2em;
    font-weight: bold;
    color: #f3f8ff;
  }
  
  .headline {
    font-size: 2em;
    font-weight: 500;
    margin: 20px 0;
    /* color: #f3f8ff; */
  }
  
  .headline em {
    font-style: italic;
    color: red; /* Soft red for emphasis */
  }
  
  .headline strong {
    color: #0d8a41; /* Light yellow for emphasis */
  }
  
  .body-text {
    font-size: 1.4em;
    line-height: 1.6;
    margin: 20px 0;
    font-style: italic;
    /* color: #f3f8ff; */
  }
  
  .body-text .highlight {
    color: red;
    font-weight: bold;
    padding-left: 5px;
  }
  
  .subtext {
    padding-left: 0;
    font-size: 1.8em;
    color: red;
  }
  
  .description {
    font-size: 1.3em;
    margin: 30px 0;
    line-height: 1.8;
    /* color: #f3f8ff; */
  }
  
  .cta-button {
    background-color: #004aad;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 1.2em;
    font-weight: bold;
    cursor: pointer;
    border-radius: 8px;
    margin-top: 30px;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #003a8f;
  }
  

  /* ----- MEDIA QUERIES FOR RESPONSIVENESS ----- */

/* Adjust layout for smaller screen sizes (tablets and mobile) */
@media (max-width: 768px) {
    .landing-container {
        margin-left: 80px; /* Leave space for the left navbar */
        margin-right: 20px; /* Add space on the right */
        margin-top: 60px;
        width: calc(100% - 100px); /* Adjust width based on left navbar */
        padding: 20px;
    }

    .logo {
        width: 50px;
    }

    .site-name {
        font-size: 1.8em;
    }

    .headline {
        font-size: 1.5em;
    }

    .body-text {
        font-size: 1.2em;
    }

    .cta-button {
        font-size: 1.1em;
        padding: 10px 20px;
    }
}
  