
.profile-container {
    width: 80%;
    max-width: 600px;
    margin: 80px auto;
    margin-top: 140px; 
    padding: 20px;
    font-family: 'Inter', sans-serif;
    border-radius: 18px;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
  }
  
  h2 {
    text-align: center;
    color: #333;
  }
  
  .input-group {
    margin-bottom: 20px;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
    color: #666;
  }
  
  .input-group input {
    width: 50%;
    margin-right: 105px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .update-btn, .create-btn {
    width: 27%;
    padding: 10px;
    margin-top: 10px;
    background-color: #0d6efd; /* Blue */
    color: white;
    border: none;
    border-radius: 18px;
    cursor: pointer;
  }
  
  .update-btn:hover, .create-btn:hover {
    background-color: #0a58ca; /* Darker blue */
  }
  
  .delete-account {
    text-align: center;
    margin-top: 20px;
  }
  
  .delete-section {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Adjust based on desired spacing */
  }
  
  .delete-section p {
    margin-left: 5px; /* Ensure some space between the button and text */
    flex: 1; /* Allows the paragraph to take up the remaining space */
    font-size: 0.9em;
    color: #666;
  }
  
  .delete-btn {
    padding: 8px 16px;
    background-color: #f44336; 
    color: white;
    border: none;
    border-radius: 18px;
    cursor: pointer;
    font-size: 0.9em;
  }
  
  .delete-btn:hover {
    background-color: #dc3545; 
  }
  
  
  .error-message {
    color: #d9534f; /* Red */
    background-color: #f2dede;
    border-color: #ebccd1;
    padding: 10px;
    margin-top: 20px;
    border-radius: 4px;
    text-align: center;
  }
  
  .subscription-section {
    display: flex;
    align-items: center; /* Align items vertically */
    margin-top: 20px;
  }
  
  .subscription-btn {
    width: 23%;
    padding: 10px 15px;
    background-color: #0d6efd;
    color: white;
    border: none;
    border-radius: 18px;
    cursor: pointer;
    margin-right: 10px; /* Space between button and info */
  }
  
  .subscription-info {
    display: flex;
    align-items: center; /* Align text vertically */
    background-color: #f4f4f4;
    padding: 10px;
    margin-right: 10px;
    border-radius: 18px;
  }
  
  .subscription-info p {
    margin: 0 10px; /* Space between elements */
  }
  
  .cancel-subscription {
    background-color: #f44336; /* Red */
  }
  
  .cancel-subscription:hover {
    background-color: #dc3545; /* Darker Red */
  }
  


/* ----- MEDIA QUERIES FOR RESPONSIVENESS ----- */

/* For devices with max-width of 768px (tablets and mobile) */
@media (max-width: 768px) {
  .profile-container {
    margin-top: 60px;
    margin-left: 80px; /* Leave space for the left navbar */
    margin-right: 20px; /* Add space on the right */
    width: calc(100% - 100px); /* Adjust width based on left navbar */
    padding: 20px;
  }

  .input-group input {
    width: 100%; /* Full width input for smaller screens */
    margin-right: 0; /* Remove margin for smaller screens */
  }

  .update-btn, .create-btn {
    width: 40%; /* Increase button width for smaller screens */
  }

  .subscription-btn {
    width: 50%; /* Increase button width for smaller screens */
  }

  .delete-section {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: flex-start;
  }

  .delete-btn {
    width: 100%; /* Full-width delete button */
    margin-bottom: 10px;
  }

  .subscription-section {
    flex-direction: column; /* Stack subscription section vertically on smaller screens */
    align-items: flex-start;
  }

  .subscription-btn {
    width: 100%;
    margin-bottom: 10px; /* Space between button and info on small screens */
  }

  .subscription-info {
    width: 100%;
    margin-right: 0;
  }
}

/* For even smaller screens (mobile phones) with max-width of 480px */
@media (max-width: 480px) {
  .profile-container {
    margin-top: 40px;
    margin-left: 70px; /* Slightly less margin for very small screens */
    margin-right: 10px;
    padding: 10px;
  }

  .update-btn, .create-btn {
    width: 100%; /* Full-width buttons on very small screens */
  }

  .subscription-btn {
    width: 100%;
  }

  .input-group input {
    width: 100%; /* Full width input for smaller screens */
  }
}