/* PrivacyPolicy.css */

.privacy-policy-container {
    margin-top: 140px;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Inter', sans-serif;
    line-height: 1.6;
  }
  
  .privacy-policy-container h1 {
    font-size: 2.5em;
    margin: 60px;
    margin-bottom: 10px;
    color: #333;
    text-align: center;
  }
  
  .effective-date {
    font-size: 0.9em;
    color: #555;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .privacy-policy-container h2 {
    font-size: 1.5em;
    color: #333;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .privacy-policy-container h3 {
    font-size: 1.2em;
    color: #555;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  
  .privacy-policy-container p {
    margin-bottom: 10px;
    color: #444;
  }
  
  .privacy-policy-container p strong {
    color: #333;
    font-weight: bold;
  }
  


/* ----- MEDIA QUERIES FOR RESPONSIVENESS ----- */

/* For devices with max-width of 768px (tablets and mobile) */
@media (max-width: 768px) {
    .privacy-policy-container {
      margin-top: 60px;
      max-width: 90%;
      margin-left: 80px; /* Leave space for the left navbar */
      margin-right: 20px; /* Add space on the right */
      padding: 15px;
    }
  
    .privacy-policy-container h1 {
      font-size: 2em;
      margin: 40px;
    }
  
    .privacy-policy-container h2 {
      font-size: 1.3em;
    }
  
    .privacy-policy-container h3 {
      font-size: 1.1em;
    }
  
    .privacy-policy-container p {
      font-size: 1em;
    }
  }
  
  /* For even smaller screens (mobile phones) with max-width of 480px */
  @media (max-width: 480px) {
    .privacy-policy-container {
      margin-top: 40px;
      margin-left: 70px; /* Slightly less margin for very small screens */
      margin-right: 10px;
      padding: 10px;
    }
  
    .privacy-policy-container h1 {
      font-size: 1.8em;
      margin: 30px;
    }
  
    .privacy-policy-container h2 {
      font-size: 1.2em;
    }
  
    .privacy-policy-container h3 {
      font-size: 1em;
    }
  
    .privacy-policy-container p {
      font-size: 0.9em;
    }
  }